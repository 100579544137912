import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function Events() {
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    fetch(`https://api.swarnimayu.com/api/GalleryImg/GetallImages/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setEvents(data.data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  // Handle Lightbox Logic
  const openLightbox = (index) => {
    setCurrentIndex(index);
  };

  const closeLightbox = () => {
    setCurrentIndex(null);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === events.length - 1 ? 0 : prevIndex + 1
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!events.length) return <p>No events available.</p>; // Handle empty array

  return (
    <>
      <section className="events-section">
        <div className="container">
          {/* Heading Section */}
          <div className="row mb-4">
            <div className="col-12 text-center">
              <h2 className="events-heading">
                Seminar of Jadav University on Elderly Care
              </h2>
            </div>
          </div>

          {/* Images Grid */}
          <div className="row">
            {events.map((event, index) => (
              <div
                className="imgspace col-md-3 mt-20 position-relative"
                key={event.id}
              >
                <img
                  src={`https://api.swarnimayu.com/${event.imageUrl}`} // Use the API-provided image URL
                  className="img-fluid imgaspectratio"
                  alt={event.title || `event-${index}`} // Use event title if available
                  onClick={() => openLightbox(index)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Modal for Zoomed Image */}
        {currentIndex !== null && (
          <div className="modal">
            <div className="modal-content">
              <button className="close-btn" onClick={closeLightbox}>
                X
              </button>
              <button className="prev-btn" onClick={goToPrevious}>
                &#8592;
              </button>
              <img
                src={`https://api.swarnimayu.com/${events[currentIndex].imageUrl}`} // Use the current image's URL
                alt={events[currentIndex].title || `Zoomed-${currentIndex}`}
                className="zoomed-image"
              />
              <button className="next-btn" onClick={goToNext}>
                &#8594;
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Events;
