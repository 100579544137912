import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EventImages() {
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api.swarnimayu.com/api/GalleryImg/GalleryImagesList")
      .then((response) => {
        setData(response.data.data || []);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
        setError(error.message);
      });
  }, []);

  const handleEventClick = (id) => {
    // alert(`Event ID: ${id}`);
    navigate(`/events/${id}`);
  };

  return (
    <>
      <section className="events-section">
        <div className="container">
          {/* Images Grid */}
          <div className="row">
            {data.map((event, index) => (
              <div
                className="imgspace col-md-3 mt-20 position-relative mt-5"
                key={event.id}
                onClick={() => handleEventClick(event.id)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={`https://api.swarnimayu.com/${event.imageUrl}`}
                  className="img-fluid imgaspectratio"
                  alt={event.title || `event-${index}`}
                />

                <div className="image-title">
                  {event.tittle || "Untitled Event"}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default EventImages;
